@import '_variables';
@import '_modal';
@import '_product';
@import '_footer-links';

#view {
  overflow-x: hidden;
  .manufacturer_name {
    font-family: "Oswald", Sans-serif;
    font-size: 14px!important;
    font-weight: lighter!important;
    min-height: 21px;
  }
  #wrapper {
    overflow-x: hidden;
  }

  .wishlist-product-bottom {
    display: flex;
  }

  .btn-indies-gold {
    margin-right: 17px;
    width: 30%;
    background: #fff;
    color: #AF986E;
    font-weight: 400;
    border: 1px solid #AF986E;
    font-size: 1.125rem;
    text-transform: uppercase;
  }

  .wishlist-products-container {
    #content {
      border: none;
    }

    .text_products,
    .wishlist-products-count {
      font-size: 16px;
      color: black;
      font-family: "Oswald", Sans-serrif;
      font-weight: 300;
    }

    .text_products {
      color: #af986e !important;
    }
  }
}
.wishlist-products-item{
  margin: 15px 0
}
.wishlist-product-link{
  flex-wrap: wrap;
}
.wishlist-product-image{
  width: 100% !important;
  height: unset!important;
  position: relative;
}
.wishlist-product-image img{
  position: unset!important;
  width: 100% !important;
  transform: translate(0)!important;
}
.wishlist-product-unavailable{
  opacity: 1;
}
.wishlist-products-item .manufacturer_name,
.wishlist-products-item .wishlist-product-price{
  margin: 0;
}
.wishlist-product .tabs{
  height: 90%;
  margin: 0;
  top: 0;
  background: rgba(255,255,255,.5);
  display: flex!important;
  align-items: center;
}

.wishlist-product .tabs .js-mailalert{
  width: 100%;
  background: #ffffff;
  padding: 10px;
  border: none;
}
.wishlist-product .tabs .js-mailalert .btn-primary{
  background: #a88d66 !important;
  margin: 0;
  height: 35px;
  border: none;
  color: #fff;
}
.wishlist-product-price{
  text-align: right;
  font-weight: 400!important;
}
.wishlist-product-title{
  font-weight: 400;
}
.wishlist-product-bottom .btn{
  width: 100%!important;
  margin-top: 0;
  margin-bottom: 0;
}
.wishlist-products-container .page-content.card{
  box-shadow: none;
}
@media (max-width: 782px){

  .wishlist-product-image{
    min-height: 360px;
    margin-bottom: 10px;
    margin: 0;
  }

  .wishlist-product-image img{
    width: 100%;
  }
  .wishlist-product-bottom{
    flex-wrap: wrap;
  }

  .wishlist-product-bottom .btn{
    width: 100%!important;
    margin: 0 0 10px!important;
  }
  .wishlist-product-availability-responsive{
    display: none;
  }
}
.wishlist-product-price .price_discount {
  font-size: .75rem;
  color: #af986e;
  font-weight: normal!important;
}
.wishlist-product-price .wishlist-product-price-promo {
  text-decoration: line-through;
  letter-spacing: 0;
  line-height: 19px;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
  margin-top: -3px;
  color: black !important;
  font-size: .75rem !important;
  font-weight: lighter !important;
}
